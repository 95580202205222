import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import ListBoardWrapperStyled from './styledComponent'

class ListBoardWrapper extends PureComponent {
  render() {
    return (
      <ListBoardWrapperStyled className={this.props.className}>
        <div className="header-container">
          <Tooltip title={this.props.title}>
            <div className="title text-style prevent-text-overflow" id={this.props.titleID}>
              {this.props.title}
            </div>
          </Tooltip>
          <div className="option-container">{this.props.filter}</div>
        </div>
        <div className="list-content-container">{this.props.children}</div>
      </ListBoardWrapperStyled>
    )
  }
}

ListBoardWrapper.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.element,
  titleID: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
}

export default ListBoardWrapper
