import styled from 'styled-components'

export default styled.div`
  margin-bottom: 50px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    height: 61px;
    .title {
      max-width: 40%;
      font-family: 'Prompt', sans-serif;
      font-weight: bold;
      font-size: 40px;
      color: ${(props) => props.theme.darkBackground};
    }
    .option-container {
      display: flex;
      align-items: center;
      .filter-btn-wrapper {
        display: flex;
        margin-right: 32px;
        .filter-button {
          font-family: 'Prompt', sans-serif;
          font-weight: 500;
          font-size: 18px;
          background: transparent;
          border: none;
          outline: none;
          color: ${(props) => props.theme.darkBackground};
          &:hover {
            cursor: pointer;
          }
          &.active {
            color: ${(props) => props.theme.jadeGreen};
          }
        }
        .vertical-line {
          height: 48px;
          border-left: 2px solid #8f969c;
          margin: 0 16px;
        }
      }
    }
  }
`
